<template>
  <div class="root">
    <b-card>
      <b-card-header>
        <b-card-title>
          <h1>Nowa usługa: {{ offer.title }}</h1>
        </b-card-title>
      </b-card-header>
    </b-card>
    <b-card>
      <b-card-header>
        <b-card-title>
          <h2>informacje:</h2>
        </b-card-title>
      </b-card-header>
      <b-card-text>
        <div class="form-group">
          <input
            id="title"
            v-model="offer.title"
            placeholder="Nazwa usługi"
            type="text"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <input
            id="title"
            v-model="offer.address"
            type="text"
            placeholder="Adres"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <input
            id="title"
            v-model="offer.phone"
            type="text"
            placeholder="Telefon kontaktowy"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <input
            id="title"
            v-model="offer.city"
            placeholder="Miasto"
            type="text"
            class="form-control"
          >
        </div>
      </b-card-text>
    </b-card>
    <b-card>
      <b-card-header>
        <b-card-title>
          <h2>Opis usługi:</h2>
        </b-card-title>
      </b-card-header>

      <editor
        v-model="offer.description"
        api-key="b8rjrbaxn62c6iwmxh1tg94bjzp309azf3n243jxljv1ndwn"
        :init="{
          height: 500,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help'
        }"
      />
    </b-card>

    <b-card>
      <h2>Obraz</h2>
      <b-form-file
        id="file"
        type="file"
        name="file"
        placeholder="Wybierz obraz"
      />
    </b-card>

    <b-card>
      <b-card-header>
        <b-card-title>
          <h2>Dodatkowe informacje: </h2>
        </b-card-title>
      </b-card-header>
      <b-card-text>
        <div class="form-group">
          <input
            id="title"
            v-model="offer.tags"
            type="text"
            placeholder="Tagi (Oddziel tagi oferty po przecinku)"
            class="form-control"
          >
        </div>
      </b-card-text>
      <div class="form-group">
        <input
          id="title"
          v-model="offer.price"
          type="number"
          placeholder="Cena (zł)"
          class="form-control"
        >
      </div>

      <div class="form-group">
        <input
          id="estaminated_time"
          v-model="offer.estaminated_time"
          type="number"
          placeholder="Czas wykonania (h)"
          class="form-control"
        >
      </div>

      <div class="form-group">
        <input
          v-model="offer.notification_message"
          type="text"
          class="form-control"
          placeholder="Zawartość wiadomości SMS"
        >
      </div>

      <div class="form-group">
        <label for="estaminated_time">Czas wysłania sms</label>
        <select
          id="estaminated_time"
          v-model="offer.notification_hours_before"
          class="form-control"
        >
          <option>12</option>
          <option>24</option>
          <option>48</option>
        </select>
      </div>

    </b-card>
    <b-card>
      <button
        class="btn btn-primary dodaj"
        @click="process()"
      >
        Dodaj usługę
      </button>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BCardText, BFormFile,
} from 'bootstrap-vue'
import swal from 'sweetalert2'
import axios from 'axios'
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'OfferAdd',
  components: {
    BCard,
    BCardText,
    editor: Editor,
    BFormFile,
  },
  data() {
    return {
      offer: {},
      categories: [],
      errors: [],
      user: {},
    }
  },
  mounted() {
    document.title = 'Dodaj usługę - Pickmdoe'
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }
    axios.get(`${process.env.VUE_APP_API_URL}user`, config)
      .then(response => {
        this.user = response.data
        this.offer.address = response.data.city
      })
      .catch(error => {
        swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      })
  },
  methods: {
    process() {
      swal.fire(
        {
          title: 'Czy jesteś pewien?',
          text: 'Nie będzie można cofnąć tej operacji!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Tak, dodaj usługę!',
        },
      ).then(result => {
        if (result.value) {
          const config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }

          if (this.offer.deposit > 1) {
            this.offer.deposit = 0
          }

          axios.post(`${process.env.VUE_APP_API_URL}Offer`, {
            title: this.offer.title,
            address: this.offer.address,
            telephone: this.offer.phone,
            description: this.offer.description,
            tags: this.offer.tags,
            price: this.offer.price,
            city: this.offer.city,
            adres: this.offer.address,
            estaminated_time: this.offer.estaminated_time,
            deposit: this.offer.deposit,
            notification_hours_before: this.offer.notification_hours_before,
            notification_message: this.offer.notification_message,
          }, config)
            .then(response => {
              // send request with file
              const formData = new FormData()
              const imagefile = document.querySelector('#file')
              formData.append('image', imagefile.files[0])
              axios.post(`${process.env.VUE_APP_API_URL}Offer/Attach-image/${response.data.success.id}`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                },
              }).then(() => {
                swal.fire({
                  title: 'Dodano usługę',
                  text: 'Usługa została dodana pomyślnie',
                  icon: 'success',
                  confirmButtonText: 'Ok',
                })

                this.$router.push('/oferty')
              }).catch(error => {
                swal.fire({
                  title: 'Dodano usługę',
                  text: 'Usługa została dodana pomyślnie',
                  icon: 'success',
                  confirmButtonText: 'Ok',
                })

                this.$router.push('/oferty')
              })
            })
            .catch(error => {
              swal.fire({
                title: 'Error',
                text: error.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok',
              })
            })
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.header {
display: flex;
justify-content: space-between;
width : 100%;
}

input {
  height: 55px;
  border-radius: 7px;
  padding: 5px;
}
.header textarea {
    width: 100%;
    height: 100px;
    background: rgba(167, 167, 167, 0.139);
    border: 1px solid rgba(204, 204, 204, 0.766);
    border-radius: 7px;
    padding: 5px;
}

::placeholder {
    color: rgba(119, 119, 119, 0.766);
}
.dodaj {
    width: 100%;
    height: 50px;
    background: rgba(167, 167, 167, 0.139);
    border: 1px solid rgba(204, 204, 204, 0.766);
    border-radius: 7px;
    padding: 5px;
    margin-top: 10px;
}
</style>
